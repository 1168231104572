import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import MenuItem from "../components/menuItemEn"
import * as mobileMenuStyles from "./mobileMenu.module.scss"

export default function MobileMenu(props) {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {extension: {eq: "md"}, name: {eq: "en"}}) {
                edges {
                    node {
                        name
                        childMarkdownRemark {
                            frontmatter {
                                title
                                services {
                                    title
                                }
                                about {
                                    title
                                }
                                news {
                                    title
                                }
                                contact {
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const home = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

    function handleClick() {
        props.onClick();
    }

    return(
        <nav className={(props.active ? `${[mobileMenuStyles.container, mobileMenuStyles.active].join(" ")}` : `${mobileMenuStyles.container}`)} aria-hidden="true">
            <header className={mobileMenuStyles.nav}>
                <div className={mobileMenuStyles.wrapper}>
                    <Link to="/" className={mobileMenuStyles.logo}>Dr. med. Martina Buchmann</Link>
                    <button onClick={handleClick} className={mobileMenuStyles.button} aria-label="Menü schließen">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17" width="24" height="17" preserveAspectRatio="xMidYMid meet">
                            <g>
                                <g transform="matrix(1,0,0,1,-128.5,243.5)" opacity="1">
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d="M0 0"></path>
                                    </g>
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d="M0 0"></path>
                                    </g>
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d="M0 0"></path>
                                    </g>
                                    </g>
                                    <g transform="matrix(1,0,0,1,-128.5,243.5)" opacity="1">
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d=" M130.20799255371094,-227.78700256347656 C130.20799255371094,-227.78700256347656 143.01400756835938,-240.59300231933594 144.23399353027344,-241.81300354003906"></path>
                                    </g>
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d=" M130.20799255371094,-241.906005859375 C130.20799255371094,-241.906005859375 143.01400756835938,-229.10000610351562 144.23399353027344,-227.8800048828125"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            </header>
            <ul className={mobileMenuStyles.list}>
                <MenuItem title={home.title} to={home.title.replace(/\s+/g, '-').toLowerCase()} class={mobileMenuStyles.item} activeClass={mobileMenuStyles.active} onClick={handleClick} />
                <MenuItem title={home.services.title} to={home.services.title.replace(/\s+/g, '-').toLowerCase()} class={mobileMenuStyles.item} activeClass={mobileMenuStyles.active} onClick={handleClick} />
                <MenuItem title={home.about.title} to={home.about.title.replace(/\s+/g, '-').toLowerCase()} class={mobileMenuStyles.item} activeClass={mobileMenuStyles.active} onClick={handleClick} />
                <MenuItem title={home.news.title} to={home.news.title.replace(/\s+/g, '-').toLowerCase()} class={mobileMenuStyles.item} activeClass={mobileMenuStyles.active} onClick={handleClick} />
                <MenuItem title={home.contact.title} to={home.contact.title.replace(/\s+/g, '-').toLowerCase()} class={mobileMenuStyles.item} activeClass={mobileMenuStyles.active} onClick={handleClick} />
            </ul>
            <ul className={`${[mobileMenuStyles.list, mobileMenuStyles.lang].join(" ")}`}>
                <li class={mobileMenuStyles.item}>
                    <Link to="/">DE</Link>
                </li>
                <li class={mobileMenuStyles.item}>
                <Link to="/en">EN</Link>
                </li>
            </ul>
        </nav>
    )
}