import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import * as footerStyles from "./footer.module.scss"

export default function Footer() {
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark(filter: {fields: {slug: {nin: ["home", "en", "impressum", "datenschutz"]}}}) {
                edges {
                    node {
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                        }
                    }
                }
            }
        }
    `)

    return (
        <footer className={footerStyles.container}>
            <nav className={footerStyles.nav}>
                <ul className={footerStyles.list}>
                    {data.allMarkdownRemark.edges.map((edge, i) => {
                        return (
                            <li className={footerStyles.item} key={i}>
                                <Link to={`/en/${edge.node.fields.slug}`}>{edge.node.frontmatter.title}</Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </footer>
    )
}