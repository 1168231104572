import React, { useEffect } from "react"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Header from "../components/headerEn"
import Footer from "../components/footerEn"
import "../styles/global.scss"

export default function Layout(props) {
  function fadeIn(elem) {
    gsap.to(
      elem,
      {
        y: 0,
        autoAlpha: 1,
        ease: "power3.easeOut",
        duration: 1,
        delay: 0.2
      }
    )

    elem.classList.remove("animate")
  }

  function hide(elem) {
    gsap.set(elem, {y: 30, autoAlpha: 0});
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.utils.toArray(".animate").forEach(function(elem) {
      hide(elem);

      gsap.delayedCall(0.2, () => {
        ScrollTrigger.create({
          trigger: elem,
          start: "top 95%",
          onEnter: function() { fadeIn(elem) },
        })
        ScrollTrigger.refresh()
      })
    });
  })

  return (
    <div>
      <Header />
      <main>
        {props.children}
      </main>
      <Footer />
    </div>
  )
}