import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Link as AnchorLink } from 'react-scroll'

export default function MenuItem(props) {
    const url = typeof document !== `undefined` ? document.location.pathname : null;
    const [width, setWidth] = useState(typeof document !== `undefined` ? window.innerWidth : null);
    const [offset, setOffset] = useState(-61);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            width >= 768 ? setOffset(-81) : setOffset(-61)
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    })

    return (
        <li className={`${props.class}`}>
            {url === "/en" ? <AnchorLink onClick={props.onClick} activeClass={`${props.activeClass}`} to={props.to} spy={true} smooth={true} offset={offset} duration={1000}>{props.title}</AnchorLink> : <Link to={"/en#" + props.to}>{props.title}</Link>}
        </li>
    )
}